import React from 'react'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import abimg from '../../images/about.jpg'
// import sign from '../../images/signeture.png'
// import DefaultModal from '../AboutModal'
import about1 from '../../images/about1.png'
import about2 from '../../images/about2.png'
import about3 from '../../images/about3.png'
import about4 from '../../images/about4.png'
import about5 from '../../images/about5.png'
import about6 from '../../images/about6.png'

const About = (props) => {

    return(
        <section id="about" className="tp-about-section">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12 col-12">
                        <div className="tp-about-wrap">
                        <div className="tp-about-img">
                            <img src={abimg} alt=""/>
                        </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-12">
                        <div className="tp-about-text">
                        <div className="tp-about-icon">
                            <i className="fi flaticon-web-design"></i>
                        </div>
                        <div className="tp-about-icon-content">
                            <h2>About Us</h2>
                            <p>We have 15 years of experience in electronic component procurement for the world’s top companies. Our European presence plays a key role in our ability to source the highest quality of components. We specialize in competitive BOM quotation for our customers and can support large volume or small volume requirements.</p>
                            <h4>More About Us</h4>
                            <p>We are experts at supply of electronic components. Our relationship with our clients is a clear indication of our commitment to them. From tiny project creators to the largest electrical manufacturers, we form partnerships. To accomplish our goals, we collaborate with the corporate, manufacturing, and buying departments of our clients. Due to our connections with top-tier industry experts, our solutions are also time and money efficient. We can give the consumer simple access to the most cutting-edge technology available.</p>
                            <p>When you work with us, you can be sure you get excellent services. We have key partners that help us ship worldwide and our team is always ready to provide any kind of support if needed.</p>
                            <p>We will do our best to find the best solution for your projects and electronic components needs.</p>
                            {/* <div className="signeture">
                                <span><img src={sign} alt=""/></span>
                                <p>Ceo Of Designlab IT</p>
                            </div> */}
                            <br></br>
                            {/* <DefaultModal buttonClass={'template-btn'}/> */}
                        </div>
                        </div>
                    </div>
                </div>

                <div className="row align-items-center">
                    <div className="col-lg-12 col-md-12 col-12">
                    <div id='service' className='service-area section-padding'>
      <div className='borderd'></div>
      <div className='container'>
        <div className='col-l2'>
          <div className='section-title section-title2 text-center'>
            <h3>What Industries we work with</h3>
            <p>We know how important it is to be diverse in terms of industries. Here are the major industries we work with:</p>
          </div>
        </div>
        <div className='row'>
          
            <div className='col-lg-4 col-md-6 col-sm-12'>
              <div className='service-section'>
                <div className='services-wrapper'>
                    <i>
                    <img src={about1} alt=""  />
                    </i>
                  <div className='service-content'>
                    <h2>Defence</h2>
                  </div>
                </div>
              </div>
            </div>
            
            <div className='col-lg-4 col-md-6 col-sm-12'>
              <div className='service-section'>
                <div className='services-wrapper'>
                    <i>
                    <img src={about2} alt=""  />
                    </i>
                  <div className='service-content'>
                    <h2>Medical</h2>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-lg-4 col-md-6 col-sm-12'>
              <div className='service-section'>
                <div className='services-wrapper'>
                    <i>
                    <img src={about3} alt=""  />
                    </i>
                  <div className='service-content'>
                    <h2>Information Technology</h2>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-lg-4 col-md-6 col-sm-12'>
              <div className='service-section'>
                <div className='services-wrapper'>
                    <i>
                    <img src={about4} alt=""  />
                    </i>
                  <div className='service-content'>
                    <h2>Automotive</h2>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-lg-4 col-md-6 col-sm-12'>
              <div className='service-section'>
                <div className='services-wrapper'>
                    <i>
                    <img src={about5} alt=""  />
                    </i>
                  <div className='service-content'>
                    <h2>Consumer Electronics</h2>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-lg-4 col-md-6 col-sm-12'>
              <div className='service-section'>
                <div className='services-wrapper'>
                    <i>
                    <img src={about6} alt=""  />
                    </i>
                  <div className='service-content'>
                    <h2>Aerospace</h2>
                  </div>
                </div>
              </div>
            </div>
         
        </div>
      </div>
      {/* <ServiceSingle open={open} onClose={handleClose} title={state.heading} doc={state.doc} image1={state.Simg1} image2={state.Simg2} image3={state.Simg3}/> */}
    </div>
                    </div>

                </div>

            </div>
            <div className="white_svg svg_white">
                <svg x="0px" y="0px" viewBox="0 186.5 1920 113.5">
                    <polygon points="0,300 655.167,210.5 1432.5,300 1920,198.5 1920,300 "></polygon>
                </svg>
            </div>
        </section>
    )
}

export default About;