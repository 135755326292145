import 'bootstrap/dist/css/bootstrap.min.css';
import React, { Fragment } from 'react';
import { Element } from 'react-scroll';
import About from '../../components/about';
import ContactSection from '../../components/ContactSection';
import Footer from '../../components/Footer';
import Hero from '../../components/Hero';
import Navbar from '../../components/Navbar';
import Portfolio from '../../components/portfolio';
import Scrollbar from '../../components/Scroolbar';
import Service from '../../components/Service';
import Testimonial from '../../components/Testimonials';


const Termspage =() => {
    return(
        <Fragment>
            <Navbar />
            <Element name="terms">
                <Testimonial/>
            </Element>
            <Footer/>
            <Scrollbar/>
        </Fragment>
    )
};
export default Termspage;


