import React from 'react';
import {BrowserRouter as Router, Switch, Route } from 'react-router-dom'

import Homepage from '../HomePage'
import Aboutpage from '../HomePage/about'
import Servicespage from '../HomePage/services'
import Termspage from '../HomePage/terms'
import Featuredpage from '../HomePage/featured'
import Quotedpage from '../HomePage/quote'

const AllRoute = () => { 

  return (
    <div className="App">
       <Router>
          <Switch>
            <Route exact path='/' component={Homepage} />
            <Route path='/home' component={Homepage} />
            <Route path='/about' component={Aboutpage} />
            <Route path='/services' component={Servicespage} />
            <Route path='/terms' component={Termspage} />
            <Route path='/featured' component={Featuredpage} />
            <Route path='/quote' component={Quotedpage} />
          </Switch>
      </Router>
      
    </div>
  );
}

export default AllRoute;
