import React from 'react';

const Service = () => {
  // const [open, setOpen] = React.useState(false);

  // function handleClose() {
  //   setOpen(false);
  // }

  // const [state, setState] = useState({});

  // const handleClickOpen = (item) => {
  //   setOpen(true);
  //   setState(item);
  // };
  const service = [
    {
      Id: '1',
      sIcon: 'fi flaticon-web-design',
      heading: 'Safe & Secure',
      iconImg: require('./../../images/real/safe-service.png'),
      des: 'We understand that for your secure and safe transaction is of utmost importance. Our Safe & Secure Purchases offers a 30-day money-back guarantee that covers all returns.',
    },
    {
      Id: '2',
      sIcon: 'fi flaticon-laptop',
      heading: 'Instant Shipping Options',
      iconImg: require('./../../images/real/ship-service.png'),
      des: 'You need your products right-away, and we understand that! We ship all orders promptly without any hiccups or delays.',
    },
    {
      Id: '3',
      sIcon: 'fi flaticon-paint-palette',
      heading: '24x7 Support',
      iconImg: require('./../../images/real/24x7-service.png'),
      des: 'We offer assistance at every step of your purchase. Our team of experts offer 24x7 support in every country.',
    },
    {
      Id: '4',
      sIcon: 'fi flaticon-smartphone',
      heading: 'Diverse Sourcing Options',
      iconImg: require('./../../images/real/diverse-service.png'),
      des: 'We are connected with over 500 manufacturers supported worldwide. If you need a product, you can be assured to find it with us.',
    },
    {
      Id: '5',
      sIcon: 'fi flaticon-verified',
      heading: 'Easy Terms',
      iconImg: require('./../../images/real/easy-tems-service.png'),
      des: 'All our purchase terms are transparent. We offer easy terms to our clients, right from the very first order.',
    },
    {
      Id: '6',
      sIcon: 'fi flaticon-operator',
      heading: 'Discounts',
      iconImg: require('./../../images/real/discounts-service.png'),
      des: 'We strive to help you save money every time. You can expect great discounts on repeat, and bulk orders.',
    },
    {
      Id: '7',
      sIcon: 'fi flaticon-operator',
      heading: 'Stocking',
      iconImg: require('./../../images/real/services7.png'),
      des: 'We provide storage for your parts! You can order many more parts than you usually do, and we will keep it in our warehouse till you need them. Save time and money!',
    },
    {
      Id: '8',
      sIcon: 'fi flaticon-operator',
      heading: 'Quality',
      iconImg: require('./../../images/real/services8.png'),
      des: 'The manufacturers we work with are some of the best in the industry. You will only get high-quality components from us!',
    },
    {
      Id: '9',
      sIcon: 'fi flaticon-operator',
      heading: 'Online Tracking',
      iconImg: require('./../../images/real/services9.png'),
      des: 'We know how important it is to know the status of your order. All the orders that we ship can be tracked at any moment via a tracking number which will be provided to you once shipping is confirmed.',
    },
  ];

  return (
    <div id='service' className='service-area section-padding'>
      <div className='borderd'></div>
      <div className='container'>
        <div className='col-l2'>
          <div className='section-title section-title2 text-center'>
            <span>Omega’s</span>
            <h2>Services</h2>
          </div>
        </div>
        <div className='row'>
          {service.map((serv, srv) => (
            <div className='col-lg-4 col-md-6 col-sm-12' key={srv}>
              <div className='service-section'>
                <div className='services-wrapper'>
                  <div className='services-icon-wrapper'>
                    <div className='service-dot'>
                      <div className='dots'></div>
                      <div className='dots2'></div>
                    </div>
                    
                    <i >
                    <img src={serv.iconImg} alt={serv.heading} style={{height:50, width: 50}} />
                    </i>
                  </div>
                  <div className='service-content'>
                    <h2>{serv.heading}</h2>
                    <p>{serv.des}</p>
                    {/* <Button
                                            className="btn"
                                            onClick={()=> handleClickOpen(serv)}>
                                            Read More
                                        </Button> */}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className='white_svg'>
        <svg x='0px' y='0px' viewBox='0 186.5 1920 113.5'>
          <polygon points='0,300 655.167,210.5 1432.5,300 1920,198.5 1920,300 '></polygon>
        </svg>
      </div>
      {/* <ServiceSingle open={open} onClose={handleClose} title={state.heading} doc={state.doc} image1={state.Simg1} image2={state.Simg2} image3={state.Simg3}/> */}
    </div>
  );
};
export default Service;
