import 'bootstrap/dist/css/bootstrap.min.css';
import React, { Fragment } from 'react';
import { Element } from 'react-scroll';
import Footer from '../../components/Footer';
import Hero from '../../components/Hero';
import Navbar from '../../components/Navbar';
import Scrollbar from '../../components/Scroolbar';


const HomePage =() => {
    return(
        <Fragment>
            <Navbar />
            <Element name='home'>
                <Hero/>
            </Element>
            {/* <PricingPlan/> */}
            {/* <Element name="blog">
                <BlogSection/>
            </Element> */}
            <Footer/>
            <Scrollbar/>
        </Fragment>
    )
};
export default HomePage;


