import 'bootstrap/dist/css/bootstrap.min.css';
import React, { Fragment } from 'react';
import { Element } from 'react-scroll';
import Footer from '../../components/Footer';
import Navbar from '../../components/Navbar';
import Portfolio from '../../components/portfolio';
import Scrollbar from '../../components/Scroolbar';

const Featuredpage =() => {
    return(
        <Fragment>
            <Navbar />
            <Element name="portfolio">
                <Portfolio/>
            </Element>
            <Footer/>
            <Scrollbar/>
        </Fragment>
    )
};
export default Featuredpage;


