import React, { Component } from 'react'


class ContactForm extends Component {


    state = {
        name: '',
        email: '',
        subject: '',
        lastname: '',
        events: '',
        notes: '',
        error: {}
    }


    changeHandler = (e) => {
        const error = this.state.error;
        error[e.target.name] = ''

        this.setState({
            [e.target.name]: e.target.value,
            error
        })
    }

    subimtHandler = (e) => {
        e.preventDefault();

        const { 
            name,
            partNo,
            company,
            manuf,
            quant,
            contact,
            error
         } = this.state;

        if (!name) {
            error.name = "Please enter your name";
        }
        if (!partNo) {
            error.partNo = "Please enter your Part Number";
        }
        if (!company) {
            error.company = "Please enter your Company Name";
        }
        if (!manuf) {
            error.manuf = "Please enter your Manufacturer";
        }
        if (!quant) {
            error.quant = "Please enter your Quantity";
        }
        if (!contact) {
            error.contact = "Please enter your Contact Email";
        }

        if (error) {
            this.setState({
                error
            })
        }
        if (error.name === '' && error.partNo === '' && error.company === '' && error.manuf === '' && error.quant === '' && error.contact === '' && error.additional === '') {
            this.setState({
                name: '',
                partNo: '',
                company: '',
                manuf: '',
                quant: '',
                contact: '',
                additional: '',
                error: {}
            })
        }
    }

    render(){

        return(
            <form onSubmit={this.subimtHandler} className="form">
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-12">
                        <div className="form-field">
                            <input value={this.state.name} onChange={this.changeHandler} type="text" name="name" placeholder="Name"/>
                            <p>{this.state.error.name ? this.state.error.name : ''}</p>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-12">
                        <div className="form-field">
                            <input value={this.state.partNo} onChange={this.changeHandler} type="text" name="partNo" placeholder="Part Number"/>
                            <p>{this.state.error.partNo ? this.state.error.partNo : ''}</p>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-12">
                        <div className="form-field">
                            <input value={this.state.company} onChange={this.changeHandler} type="text" name="company" placeholder="Company Name"/>
                            <p>{this.state.error.company ? this.state.error.company : ''}</p>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-12">
                        <div className="form-field">
                            <input value={this.state.manuf} onChange={this.changeHandler} type="text" name="manuf" placeholder="Manufacturer"/>
                            <p>{this.state.error.manuf ? this.state.error.manuf : ''}</p>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-12">
                        <div className="form-field">
                            <input value={this.state.quant} onChange={this.changeHandler} type="text" name="quant" placeholder="Quantity"/>
                            <p>{this.state.error.quant ? this.state.error.quant : ''}</p>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-12">
                        <div className="form-field">
                            <input value={this.state.contact} onChange={this.changeHandler} type="text" name="contact" placeholder="Contact Email"/>
                            <p>{this.state.error.contact ? this.state.error.contact : ''}</p>
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div className="form-field">
                            <textarea name="additional" placeholder="Additional Instructions"></textarea>
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div className="form-submit">
                            <button type="submit" className="template-btn">Submit For Quote</button>
                        </div>
                    </div>
                </div>
            </form>
        )
    }

}
export default  ContactForm;