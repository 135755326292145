import React from 'react';
// import mn1 from '../../images/protfolio/minimalism/img-1.jpg';
// import mn2 from '../../images/protfolio/minimalism/img-2.jpg';
// import mn3 from '../../images/protfolio/minimalism/img-3.jpg';

// import ab1 from '../../images/protfolio/abstract/img-1.jpg';
// import ab2 from '../../images/protfolio/abstract/img-2.jpg';
// import ab3 from '../../images/protfolio/abstract/img-3.jpg';

// import d1 from '../../images/protfolio/3d/img-1.jpg';
// import d2 from '../../images/protfolio/3d/img-2.jpg';
// import d3 from '../../images/protfolio/3d/img-3.jpg';

// import m1 from '../../images/protfolio/modern/img-1.jpg';
// import m2 from '../../images/protfolio/modern/img-2.jpg';
// import m3 from '../../images/protfolio/modern/img-3.jpg';

// import v1 from '../../images/protfolio/visual/img-1.jpg';
// import v2 from '../../images/protfolio/visual/img-2.jpg';
// import v3 from '../../images/protfolio/visual/img-3.jpg';

// import p1 from '../../images/protfolio/pdesign/img-1.jpg';
// import p2 from '../../images/protfolio/pdesign/img-2.jpg';
// import p3 from '../../images/protfolio/pdesign/img-3.jpg';

import './style.css';

const Portfolio = () => {
  // const [open, setOpen] = React.useState(false);

  // function handleClose() {
  //   setOpen(false);
  // }

  // const [state, setState] = useState({});

  // const handleClickOpen = (item) => {
  //   setOpen(true);
  //   setState(item);
  // };

  const portfolio = [
    {
      Id: '1',
      heading: 'Testing',
      image: require('./../../images/real/featured-1.jpg'),
      des: 'All components are tested prior to shipping to ensure that  they comply with all sellable parameters.',
    },
    {
      Id: '2',
      heading: 'Technical Support',
      image: require('./../../images/real/featured-2.jpg'),
      des: 'Our support team is constantly available to answer any questions you might have.  ',
    },
    {
      Id: '3',
      heading: 'Warranty',
      image: require('./../../images/real/featured-3.jpg'),
      des: 'You get a 24-month warranty on all components that we sell in addition to the warranties that are provided by the manufacturer.',
    },
    {
      Id: '4',
      heading: 'Huge Selection',
      image: require('./../../images/real/featured-4.png'),
      des: 'We have more than 2,000,000 parts available from our network. We want to make sure you can find exactly what you need with OMEGA.',
    },
    {
      Id: '5',
      heading: 'Customization',
      image: require('./../../images/real/featured-5.jpg'),
      des: 'In case your company might need certain modified parts, we can help. Just let us know when you get in touch with our contractors, and we will help you as best as we can!',
    },
    {
      Id: '6',
      heading: 'Professional Team',
      image: require('./../../images/real/featured-6.jpg'),
      des: 'You can count on experts to help you! With over 15 years of experience, you will not be able to find a more fitting team to work with you. Provide us with the information you need and watch us live up to our name!',
    },
  ];

  return (
    <div className='tp-protfolio-area section-padding'>
      <div className='container'>
        <div className='col-12'>
          <div className='section-title text-center'>
            <span>Omega’s</span>
            <h2>Featured Capabilities</h2>
          </div>
        </div>
        <div className='row'>
          <div className='col-12'>
            <div className='tp-protfolio-item'>
              <div className='row'>
                {portfolio.map((port, prt) => (
                  <div className='col-lg-4 col-md-6 col-sm-12 custom-grid' key={prt}>
                    <div className=''>
                      <div className='tp-protfolio-single'>
                        <div className='tp-protfolio-img'>
                          <img src={port.image} alt='' />
                        </div>
                        <div className='tp-protfolio-text'>
                          
                          
                          {/* <span>{port.subHeading}</span> */}
                          {/* <Link to='/home' onClick={() => handleClickOpen(port)}>
                            View Work
                          </Link> */}
                        </div>
                        <h2>{port.heading}</h2>
                        <p>{port.des}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <PortfolioSingle
        open={open}
        onClose={handleClose}
        title={state.heading}
        doc={state.doc}
        image1={state.pImg1}
        image2={state.pImg2}
        image3={state.pImg3}
        authorName={state.authorName}
        videosId={state.videosId}
        value={state.value}
        date={state.date}
      /> */}

      <div className='white_svg svg_white'>
        <svg x='0px' y='0px' viewBox='0 186.5 1920 113.5'>
          <polygon points='0,300 655.167,210.5 1432.5,300 1920,198.5 1920,300 '></polygon>
        </svg>
      </div>
    </div>
  );
};

export default Portfolio;
