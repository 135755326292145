import React, { Component } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
// import testimonialImg_1 from '../../images/testimonial/1.jpg';
// import testimonialImg_2 from '../../images/testimonial/2.jpg';
// import testimonialImg_3 from '../../images/testimonial/3.jpg';

class Testimonial extends Component {
  render() {
    const settings = {
      dots: false,
      infinite: true,
      arrows: true,
      fade: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      centerPadding: 30,
      focusOnSelect: false,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 2,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    return (
      <div className='testimonial-area'>
        <div className='container'>
          <div className='testimonial-active'>
            <div className='testimonial-wrap'>
              <Slider {...settings}>
                <div className='testimonial-item'>
                  <div className='testimonial-content'>
                    <div className='testimonial-img'>{/* <img src={testimonialImg_1} alt="" /> */}</div>
                    <div className='testimonial-content'>
                      <h4>Terms</h4>
                      <span>
                        The agreement between OMEGA and the buyer regarding the sale of the materials supplied under the terms below is represented by
                        the terms and conditions below, and this agreement cannot be changed unless a new written contract covering the sale of those
                        materials is signed by OMEGA and the buyer.
                      </span>
                      {/* <p>It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is the therefore always free from repetition, injected humour,sure there embarrassing the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined Lorem Ipsum is therefore always free from repetition.</p> */}
                      <ul style={{ textAlign: 'left' }}>
                        <li>THESE TERMS GOVERN ALL TRANSACTIONS. Sales orders cannot be changed or canceled without OMEGA's permission. </li>
                        <li>
                          Neither party shall be liable for delivery delays brought on by strikes, material shortages, transportation delays, or other
                          unforeseen circumstances.
                        </li>
                        <li>
                          Every shipment will be FOB at the shipping location. If no instructions are given, OMEGA is granted the authority to choose
                          the carrier. Any transit damage is entirely the buyer's fault.
                        </li>
                        <li>
                          The buyer shall be liable for all costs incurred by OMEGA in connection with the sale, purchase, delivery, or processing of
                          any of the material.
                        </li>
                        <li>
                          The OMEGA credit department must approve shipments before they are made. If the buyer doesn't follow the payment terms,
                          OMEGA has the right to postpone additional shipments or cancel any remaining amount. The buyer consents to reimburse OMEGA
                          immediately for any reasonable collection costs incurred because of the buyer's default.
                        </li>
                        <li>
                          Immediately upon receipt, the buyer is required to inspect all items and may reject those that are broken, faulty, or
                          significantly fall short of the manufacturer's standards. When rejecting any goods, the buyer must seek a return material
                          authorization (RMA) number from the seller within seven (7) days of receiving any damaged goods and one (1) year after
                          receiving any undamaged items that don't match manufacturer standards.
                        </li>
                        <li>
                          The Goods shall be conclusively deemed to be accepted unless and until Buyer notifies Seller in writing of Buyer's rejection
                          and the grounds why.
                        </li>
                        <li>
                          The Buyer shall ship the Rejected Goods back to Seller, freight prepaid, within fifteen (15) days of receiving the written
                          RMA number, with the RMA number clearly visible on the outside of the box. In cases where the RMA number is not prominently
                          displayed, the seller maintains the right to refuse to accept any returned goods.
                        </li>
                        <li>
                          Seller's sole and exclusive liability shall be, at Seller's option, to replace such Goods, refund the purchase price, or
                          credit Buyer's account if the Goods furnished by Seller fail to conform to this Agreement, including Seller's warranty that
                          the Goods furnished meet manufacturer's specifications, provided that a) Seller is notified in writing within the time
                          period specified above, with a detailed explanation of any alleged deficiencies, and b) such Goods are returned to Seller.
                        </li>
                        <li>
                          The products purchased here will be in accordance with the specifications stated on the purchase order, according to OMEGA's
                          warranty. Omega's liability will never go over the purchase price that was paid to Omega.
                        </li>
                        <li>
                          Omega expressly disclaims any warranties that are not set forth herein. No warranties, express or implied, are made by Omega
                          as to the merchantability, fitness for a particular purpose, or the validity of any patent or copyright as to the
                          product(s). Omega shall not be liable to the buyer or any other party for any loss of property, loss of profits, or damages
                          arising out of the use or inability to use the equipment in which the product is installed, or indirect or other similar
                          damages arising from breach of warranty, breach of contract, negligence.
                        </li>
                      </ul>
                    </div>
                    <div className='testimonial-icon'>
                      <i className='fi flaticon-right-quote'></i>
                    </div>
                  </div>
                </div>
                {/* <div className='testimonial-item'>
                  <div className='testimonial-content'>
                    <div className='testimonial-img'>
                      <img src={testimonialImg_2} alt='' />
                    </div>
                    <div className='testimonial-content'>
                      <h4>Lily Watson</h4>
                      <span>SEO of Art</span>
                      <p>
                        It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum
                        which looks reasonable. The generated Lorem Ipsum is the therefore always free from repetition, injected humour,sure there
                        embarrassing the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined Lorem Ipsum is
                        therefore always free from repetition.
                      </p>
                    </div>
                    <div className='testimonial-icon'>
                      <i className='fi flaticon-right-quote'></i>
                    </div>
                  </div>
                </div>
                <div className='testimonial-item'>
                  <div className='testimonial-content'>
                    <div className='testimonial-img'>
                      <img src={testimonialImg_3} alt='' />
                    </div>
                    <div className='testimonial-content'>
                      <h4>Martin Gaptil</h4>
                      <span>SEO of Alka</span>
                      <p>
                        It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum
                        which looks reasonable. The generated Lorem Ipsum is the therefore always free from repetition, injected humour,sure there
                        embarrassing the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined Lorem Ipsum is
                        therefore always free from repetition.
                      </p>
                    </div>
                    <div className='testimonial-icon'>
                      <i className='fi flaticon-right-quote'></i>
                    </div>
                  </div>
                </div> */}
              </Slider>
            </div>
          </div>
        </div>
        <div className='white_svg'>
          <svg x='0px' y='0px' viewBox='0 186.5 1920 113.5'>
            <polygon points='0,300 655.167,210.5 1432.5,300 1920,198.5 1920,300 '></polygon>
          </svg>
        </div>
      </div>
    );
  }
}

export default Testimonial;
