import 'bootstrap/dist/css/bootstrap.min.css';
import React, { Fragment } from 'react';
import { Element } from 'react-scroll';
import About from '../../components/about';
import Footer from '../../components/Footer';
import Navbar from '../../components/Navbar';
import Scrollbar from '../../components/Scroolbar';

const Aboutpage =() => {
    return(
        <Fragment>
            <Navbar />
            <Element name='about'>
                 <About/>
            </Element>
            <Footer/>
            <Scrollbar/>
        </Fragment>
    )
};
export default Aboutpage;


