import React, { Component } from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll'
import Slides from '../../images/slide-3.jpg';
import {NavLink} from 'react-router-dom'
import services1 from '../../images/services1.png'
import services2 from '../../images/services2.png'

class Hero extends Component {
    render() {
        return(

            <>
            <section id="home" className="hero hero-slider-wrapper hero-style-1">
                <div className="hero-slider">
                    <div className="slide" style={{ backgroundImage: `url(${Slides})` }}>
                        <div className="container">
                            <div className="row">
                                <div className="col col-md-10 col-sm-12 slide-caption">
                                    <div className="slide-subtitle">
                                        <h4>Smart Products and Cost-Effective Options</h4>
                                    </div>
                                    <div className="slide-title">
                                        <h2>Omega</h2>
                                    </div>
                                    <div className="btns">
                                        <NavLink to="/quote" className="template-btn go-contact-area">GET IN TOUCH</NavLink>
                                    </div>
                                </div>
                            </div>

                            

                        </div>
                    </div>
                </div>

               

                {/* <div className="social-links">
                    <div className="overlay"></div>
                    <ul>
                        <li><a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/"><i className="fa fa-facebook"></i></a></li>
                        <li><a target="_blank" rel="noopener noreferrer" href="https://twitter.com/"><i className="fa fa-twitter"></i></a></li>
                        <li><a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/"><i className="fa fa-linkedin"></i></a></li>
                        <li><a target="_blank" rel="noopener noreferrer" href="https://www.pinterest.com/"><i className="fa fa-pinterest"></i></a></li>
                    </ul>
                </div> */}
                <div className="white_svg">
                    <svg x="0px" y="0px" viewBox="0 186.5 1920 113.5">
                        <polygon points="0,300 655.167,210.5 1432.5,300 1920,198.5 1920,300 "></polygon>
                    </svg>
                </div>

                

            </section>
<br></br><br></br>
            <section>
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-12 col-md-12 col-12">
                    <div id='service' className='service-area section-padding'>
      <div className='borderd'></div>
      <div className='container'>
        <div className='col-l2'>
          <div className='section-title section-title2 text-center'>
            <h2>Our Mission </h2>
            <p>Our mission is to provide high-quality sourcing services for companies all over the world no matter their industry or scale.       </p>
          </div>
        </div>
        <div className='row'>
          
            <div className='col-lg-6 col-md-6 col-sm-12'>
              <div className='service-section'>
                <div className='services-wrapper'>
                    
                    <i>
                    <img src={services1} alt=""  />
                    </i>
                  
                  <div className='service-content'>
                    <h2>Trusted Company</h2>
                    <p>We have over 3000+ clients that have worked with us up to this day.  </p>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-lg-6 col-md-6 col-sm-12'>
              <div className='service-section'>
                <div className='services-wrapper'>
                  
                    <i>
                    <img src={services2} alt=""  />
                    </i>
                  
                  <div className='service-content'>
                    <h2>Experience</h2>
                    <p>With a rich history in this industry, OMEGA provides excellent sourcing services.</p>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-lg-3 col-md-6 col-sm-12'>
              <div className='service-section'>
                <div className='services-wrapper'>
                  <div className='service-content'>
                    <h2>7</h2>
                    <p>Warehouses</p>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-lg-3 col-md-6 col-sm-12'>
              <div className='service-section'>
                <div className='services-wrapper'>
                  <div className='service-content'>
                    <h2>1M + </h2>
                    <p>Parts sold</p>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-lg-3 col-md-6 col-sm-12'>
              <div className='service-section'>
                <div className='services-wrapper'>
                  <div className='service-content'>
                    <h2>3000+</h2>
                    <p>Satisfied Customers</p>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-lg-3 col-md-6 col-sm-12'>
              <div className='service-section'>
                <div className='services-wrapper'>
                  <div className='service-content'>
                    <h2>15+</h2>
                    <p>Years of Experience</p>
                  </div>
                </div>
              </div>
            </div>

         
        </div>
      </div>
      <div className='white_svg'>
        <svg x='0px' y='0px' viewBox='0 186.5 1920 113.5'>
          <polygon points='0,300 655.167,210.5 1432.5,300 1920,198.5 1920,300 '></polygon>
        </svg>
      </div>
      {/* <ServiceSingle open={open} onClose={handleClose} title={state.heading} doc={state.doc} image1={state.Simg1} image2={state.Simg2} image3={state.Simg3}/> */}
    </div>
                    </div>

                </div>
            </div>
            
            </section>
            </>

        )
    }
}

export default Hero;